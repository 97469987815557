.link-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.7em;
}
.link-info:first-child {
  padding-top: 10px;
}

.link-info span {
}
.link-info img {
  transform: scale(0.5);
}

.link-info .shopify-name {
  margin-left: 10px;
}
.link-info .shopify-name:before {
  content: '\2022';
  color: green;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

ul.link-infos {
  padding-left: 20px;
  margin: 0;
}
